import * as React from "react";
import { useEffect, useState, useMemo } from "react";
import "devextreme/dist/css/dx.light.css";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DEButton,
  getUserInfo,
  capitalizeFirstLetter
} from 'utils/services/Helpers';
import { Form, GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { ProgressSteps } from "../../../../components/CustomDataGridComponents";
import { CustomFormFileInput, FormFileUploader, FormDateBox, FormViewJD } from "../../../../components/CustomDataGridComponents";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import useApprovals from "utils/hooks/useApprovals";
import useHeadcountRequest from "utils/hooks/useHeadcountRequest";
import InternalMobilityPopup from "../../components/InternalMobilityPopup";
import CloneHeadCountPopup from "../../components/CloneHeadcountPopup";
import HcHistoryTable from "./HcHistoryTable"
import { Link } from "react-router-dom";
import ActionDropdown from "views/manage-headcounts/components/ActionDropdown";
import BriefingTemplateModal from "components/Modal/BriefingTemplateModal";

const HcFormDetails = React.memo(({ formHcData, hcDataAccessValues, hcDropdownData, hcEditableFields, columns, dropDownData, apiCallBack, permissions, routeKey, masterRequestTypeIdForReplacements = null, isReplacement = false, leaverActionEnabled = true, dataSource, setDataSource, dgInstance, canApproveReject, selectedRowKeys, setSelectedRowKeys, pagesAndPermissions, dataAccess }) => {
  const { bulkRollback, approveOrReject, handleHoldOrReActivateRequest, cancelGroupRequest, endFreelancerRequest, cancelRequestBeforeApproval } = useApprovals()
  const { updateOrCreateHeadcountRequest, hcJdView } = useHeadcountRequest(routeKey);
  const [hcData, setHcData] = useState({});
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [haveFormError, setHaveFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(null);
  const [formClassName, setFormClassName] = useState(null);
  const [masterRequestTypeId, setMasterRequestTypeId] = useState(null);
  const [masterHeadcountTypeId, setMasterHeadcountTypeId] = useState(null);
  const internalEmpData = JSON.parse(localStorage.getItem('internalEmpData'));
  const masterTransactionType = "hc_transaction"
  const masterModuleId = 3
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [actionDropdownDatasource, setActionDropdownDatasource] = useState([]);
  const MAX_DISPLAY_TAGS = 10

  useEffect(() => {
    if (formHcData) {

      let formClass = null
      if (formHcData.hasOwnProperty('cancelled') && formHcData.cancelled) formClass = "cancelled"
      else if (formHcData.hasOwnProperty('needApproval') && formHcData.needApproval) formClass = "needApproval"
      else if (formHcData.hasOwnProperty('status') && formHcData.status) formClass = "approved"
      else if (formHcData?.status === false && formHcData?.isLive === false) formClass = "rejected"
      setFormClassName(formClass)

      if (hcEditableFields) {
        // add the editable fields to the form
        let hcEditableFieldsItems = {}
        hcEditableFields.map(data => {
          if (!formHcData.hasOwnProperty(data.dataIndex)) {
            hcEditableFieldsItems[data.dataIndex] = null
          }
        })
        setHcData({
          ...formHcData,
          ...hcEditableFieldsItems
        })
      }
      else {
        setHcData(formHcData)
      }

      if (formHcData?.transaction_headcount_group && formHcData?.transaction_headcount_group?.masterRequestTypeId) {
        setMasterRequestTypeId(formHcData.transaction_headcount_group.masterRequestTypeId)
      }

      if (isReplacement && masterRequestTypeIdForReplacements !== null) {
        setMasterHeadcountTypeId(masterRequestTypeIdForReplacements)
      }
      else if (formHcData?.transaction_headcount_group && formHcData?.transaction_headcount_group?.masterHeadcountTypeId) {
        setMasterHeadcountTypeId(formHcData.transaction_headcount_group.masterHeadcountTypeId)
      }
    }

    // cleanup on unmount
    return () => {
      setHcData({})
      setFormClassName(null)
      setMasterRequestTypeId(null)
      setMasterHeadcountTypeId(null)
    }
  }, []);

  useEffect(() => {
    if (Object.keys(hcData).length) {
      const actions = [
        {
          text: 'Reject',
          visible: canApproveReject && hcData.actionedByApproverId === null && hcData.needApproval && permissions && permissions.canApprove,
          onClick: handleReject
        },
        {
          text: 'Convert to Freelancer',
          visible: false, /* commented because of requirement change !hasDataChanged && hcData.actionedByApproverId === null && !hcData.needApproval && hcData.cStatus !== "Closed" && hcData.status && !hcData.onHold && hcData.canConvertToFreelancer && hcData.cancelled !== true && permissions && permissions.canCreate && leaverActionEnabled, */
          onClick: handleConvertToFreelancer
        },
        {
          text: 'Hold',
          visible: hcData.actionedByApproverId === null && hcData.needApproval && hcData.canApprove && hcData.hold_reason === null ? true : !hasDataChanged && hcData.cStatus !== "Closed" && hcData.status && hcData.canHoldOrReactivate && hcData.cancelled !== true,
          onClick: handleHold
        },
        {
          text: 'Reactivate',
          visible: hcData.needApproval && hcData.actionedByApproverId === getUserInfo()?.id && hcData.activation_reason === null ? true : !hasDataChanged && !hcData.needApproval && hcData.cStatus !== "Closed" && hcData.status && hcData.canReactivate,
          onClick: handleReactivate
        },
        {
          text: 'Rollback',
          visible: hcData?.status === null && canApproveReject && hcData.actionedByApproverId === null && hcData.needApproval && permissions && permissions.canApprove,
          onClick: handleRollback
        },
        {
          text: hcData?.canCancelRequestBeforeApproval  ?  `Undo ${hcData?.cancelRequestType?.toLowerCase() === "freelancer"?.toLowerCase() ? "Convert to Freelancer" : capitalizeFirstLetter(hcData?.cancelRequestType)}` : 'Cancel Request',
          visible: hcData?.canCancelRequestBeforeApproval ? true : !hasDataChanged && hcData.actionedByApproverId === null && !isReplacement && !hcData.needApproval && hcData.cStatus !== "Closed" && !hcData.onHold && hcData.status && hcData.canCancel && permissions && permissions.canCreate,
          onClick: hcData?.canCancelRequestBeforeApproval ? handleCancelBeforeApproval : handleCancel
        },
        {
          text: 'End Freelancer Request',
          visible: !hasDataChanged && hcData.actionedByApproverId === null && !hcData.needApproval && hcData.cStatus !== "Closed" && hcData.status && !hcData.onHold && hcData.endFreelancerRequest && hcData.cancelled !== true,
          onClick: handleEndFreelancer
        },
        {
          text: 'Clone',
          visible: permissions && permissions.canCreate,
          onClick: () => setPopupVisibility(true)
        }
      ];

      const filteredActions = actions?.filter(action => action?.visible);
      setActionDropdownDatasource(filteredActions);
    }
  }, [hcData, hasDataChanged, permissions, canApproveReject, isReplacement, leaverActionEnabled]);

  /**
   * Handle Form Data Change
   **/
  const formFieldDataChanged = (e) => {
    setHasDataChanged(true)
    if (e?.dataField === "masterBudgetStatusId") {
      if (e.value === 1)
        setHcData({
          ...hcData,
          [e.dataField]: e.value,
          ['budget']: null,
          ['masterCurrencyId']: null
        })
      else
        setHcData({
          ...hcData,
          [e.dataField]: e.value,
        })
    }
    else
      setHcData({
        ...hcData,
        [e.dataField]: e.value
      })

  }

  /**
   * Handle Validate Form Data
   **/
  const handleValidateForm = (submitCase = null) => {
    let isValid = true
    if (submitCase === "convertFreelancer") {
      if (hcData.masterGigId === null) {
        isValid = false
        setHaveFormError(true)
        setFormErrorMsg('Please enter the Gig!')
      }
      else if (hcData.startDate === null) {
        isValid = false
        setHaveFormError(true)
        setFormErrorMsg('Please enter the Start Date!')
      } else if (hcData.endDate === null) {
        isValid = false
        setHaveFormError(true)
        setFormErrorMsg('Please enter the End Date!')
      } else if (hcData?.masterJobTypeId === 2 && hcData?.startDate && hcData?.endDate && hcData?.endDate < hcData?.startDate) {
        isValid = false
        setHaveFormError(true)
        setFormErrorMsg('End Date cannot be less than Start Date')
      } else {
        isValid = true
        setHaveFormError(false)
        setFormErrorMsg(null)
      }
    }
    else if (submitCase === "approve-hc" && hcData.masterBudgetStatusId !== 1 && (hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterBudgetStatusId")?.required : false)) {
      if (hcData.masterBudgetStatusId === null) {
        isValid = false
        setHaveFormError(true)
        setFormErrorMsg('Please enter the Budget Status!')
      } else if (hcData.masterCurrencyId === null) {
        isValid = false
        setHaveFormError(true)
        setFormErrorMsg('Please enter the Currency!')
      } else if (hcData.budget === null) {
        isValid = false
        setHaveFormError(true)
        setFormErrorMsg('Please enter the Budget!')
      } else {
        isValid = true
        setHaveFormError(false)
        setFormErrorMsg(null)
      }
    }

    return isValid
  }

  /**
   * Handle Form Submit
   **/
  const handleSubmit = async () => {
    const isValid = handleValidateForm("convertFreelancer");
    if (isValid) {
      setHasDataChanged(false)
      await updateOrCreateHeadcountRequest([hcData], masterRequestTypeId, masterHeadcountTypeId, internalEmpData, false, apiCallBack)
    }
  }

  /**
   * Handle Cancel Convert To Freelancer Changes
   **/
  const handleCancelConvert = () => {
    setHcData({
      ...hcData,
      wantsToConvertToFreelancer: false,
      edited: false,
      rowEdited: false,
      masterJobTypeId: 1,
    })
    setHasDataChanged(false)
    setHaveFormError(false)
    setFormErrorMsg(null)
  }

  /**
   * Handle Convert To Freelancer
   **/
  const handleConvertToFreelancer = () => {
    setHcData({
      ...hcData,
      wantsToConvertToFreelancer: true,
      edited: true,
      rowEdited: true,
      masterJobTypeId: 2,
      masterGigId: hcData.masterGigId !== null ? hcData.masterGigId : null,
      startDate: hcData.startDate !== null ? hcData.startDate : null,
      endDate: hcData.endDate !== null ? hcData.endDate : null
    })
    setHasDataChanged(true)
  }


  /**
   * Handle Approve Request
   **/
  const handleFormAdd = async () => {
    const isValid = handleValidateForm("approve-hc", hcData);
    if (isValid) {
      if (isReplacement) {
        const ds = [...dataSource]
        const updatedDataSource = ds.map(d => {
          const updatedData = { ...d.data }
          if (d.id === hcData.transactionLeaverId) {
            if (updatedData.data.headcounts.some(d => d.id === hcData.id)) {
              updatedData.data.headcounts = updatedData.data.headcounts.map(hc => {
                if (hc.id === hcData.id) return { ...hcData, isRequiredFieldsFilled: true }
                else return { ...hc }
              })
            }
          }
          return { ...d, data: updatedData }

        })
        setDataSource(updatedDataSource)
        if (selectedRowKeys && selectedRowKeys.length) {
          const sds = [...selectedRowKeys]
          const updatedSelectedDataSource = sds.map(d => {
            const updatedData = { ...d.data }
            if (d.id === hcData.transactionLeaverId) {
              if (updatedData.data.headcounts.some(d => d.id === hcData.id)) {
                updatedData.data.headcounts = updatedData.data.headcounts.map(hc => {
                  if (hc.id === hcData.id) return { ...hcData, isRequiredFieldsFilled: true }
                  else return { ...hc }
                })
              }
            }
            return { ...d, data: updatedData }

          })
          setSelectedRowKeys(updatedSelectedDataSource)
        }
        dgInstance?.collapseAll(-1)
      }
      else {
        const ds = [...dataSource]
        const updatedDataSource = ds.map(obj => obj.id === hcData.id ? { ...obj, data: { ...obj.data, ['data']: hcData, isRequiredFieldsFilled: true } } : obj);
        setDataSource(updatedDataSource)
        if (selectedRowKeys && selectedRowKeys.length) {
          const sds = [...selectedRowKeys]
          const updatedSelectedDataSource = sds.map(obj => obj.id === hcData.id ? { ...obj, data: { ...obj.data, ['data']: hcData, isRequiredFieldsFilled: true } } : obj);
          setSelectedRowKeys(updatedSelectedDataSource)
        }
        dgInstance?.collapseAll(-1)
      }

    }
  }

  let toShowCats = []
  if (!toShowCats.length) toShowCats = process.env.REACT_APP_CATSTOSHOW ? process.env.REACT_APP_CATSTOSHOW?.split(',')?.map(Number) : []

  /**
   * Handle Approve Request
   **/
  const handleApprove = async () => {
    const isValid = handleValidateForm("approve-hc", hcData);
    if (isValid) {
      await approveOrReject(true, [hcData], masterTransactionType, masterModuleId, null, apiCallBack)
    }
  }

  /**
   * Handle Reject Request
   **/
  const handleReject = async () => {
    await approveOrReject(false, [hcData], masterTransactionType, masterModuleId, null, apiCallBack)
  }

  /**
   * Handle Hold Request
   **/
  const handleHold = async () => {
    await handleHoldOrReActivateRequest(hcData.id, true, masterTransactionType, masterModuleId, hcData.needApproval && hcData.canApprove, apiCallBack)
  }

  /**
   * Handle Reactivate Request
   **/
  const handleReactivate = async () => {
    await handleHoldOrReActivateRequest(hcData.id, hcData?.canApprove && hcData.needApproval && hcData?.actionedByApproverId === getUserInfo()?.id && hcData.hold === null ? false : hcData.hold !== true, masterTransactionType, masterModuleId, hcData.needApproval && hcData.canApprove, apiCallBack)
  }

  /**
   * Handle Cancel Request
   **/
  const handleCancel = async () => {
    await cancelGroupRequest(hcData.id, masterTransactionType, false, false, apiCallBack)
  }

  const handleCancelBeforeApproval = async () => {
    await cancelRequestBeforeApproval(hcData.id, masterTransactionType, hcData?.cancelRequestType, apiCallBack)
  }

  /**
   * Handle End Freelancer Request
   **/
  const handleEndFreelancer = async () => {
    endFreelancerRequest(hcData.id, masterTransactionType, masterModuleId, apiCallBack)
  }

  /**
 * Handle Approve Request
 **/
  const handleRollback = async () => {
    await bulkRollback([hcData], isReplacement ? "replacements" : masterTransactionType, masterModuleId, null, apiCallBack)
  }

  /**
   * Return Edit Data Format
   **/
  const returnEditedDataFormat = (data) => {
    return {
      ...data,
      edited: true,
      rowEdited: true,
    }
  }

  const onMultiTagPreparing = (args) => {
    const selectedItemsLength = args.selectedItems.length;

    if (selectedItemsLength < MAX_DISPLAY_TAGS) {
      args.cancel = true;
    } else {
      const allClientsSelected = selectedItemsLength === hcDropdownData?.master_clients?.length;
      args.text = allClientsSelected ? `All Clients` : `${selectedItemsLength} Clients`
    }
  };

  function generateLink(hcData) {
    const link = `/views/group/${hcData?.transaction_headcount_group?.id}/request/${hcData?.transaction_headcount_group?.masterRequestTypeId}/type/${hcData?.transaction_headcount_group?.masterHeadcountTypeId ?? "leaver"}/manage-headcount`
    return `${link}?id=${hcData?.transaction_headcount_group?.masterHeadcountTypeId ? hcData?.id : hcData?.transactionLeaverId}${!hcData?.transaction_headcount_group?.masterHeadcountTypeId ? `&hId=${hcData?.id}` : ""}`
  }

  return (
    <React.Fragment>
      {
        Object.keys(hcData).length ?
          <MDBox id="HcFormDetailsContainer">
            <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
            <CloneHeadCountPopup columns={columns} headcounts={[hcData]} getHcByIdDetails={[hcData.id]} routeKey={routeKey} dropDownData={hcDropdownData} setPopupVisibility={setPopupVisibility} isPopupVisible={isPopupVisible} pagesAndPermissions={pagesAndPermissions} dataAccess={dataAccess} />
            {isReplacement && hcData && hcData?.steps && hcData?.steps.length ?
              <div style={{ padding: "16px" }}>
                <MDBox pt={2} pb={1}>
                  <ProgressSteps steps={hcData.steps} />
                </MDBox>
              </div>
              : null
            }
            <div style={{ padding: "16px" }} className={formClassName}>
              <Form key={hcData.id} id="HcFormDetails" className="HcFormContent"
                onFieldDataChanged={formFieldDataChanged}
                formData={hcData}
                width={"100%"} style={{ marginBottom: "30px" }}>
                <GroupItem colCount={2}>

                  <SimpleItem dataField={"reqCode"} editorType="dxTextBox" name={"reqCode"}
                    disabled={true}
                    visible={isReplacement && hcData?.hasOwnProperty('reqCode') && hcData?.reqCode !== null}
                    editorOptions={{
                      disabled: true
                    }}>
                    <Label text={"HC ID"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterOrgDivisionId"} editorType="dxSelectBox" name={"masterOrgDivisionId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterOrgDivisionId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterOrgDivisionId') && hcData.masterOrgDivisionId !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterOrgDivisionId']
                      },
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterOrgDivisionId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterOrgDivisionId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Division"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterOrgEntityId"} editorType="dxSelectBox" name={"masterOrgEntityId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterOrgEntityId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterOrgEntityId') && hcData.masterOrgEntityId !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterOrgEntityId']
                      },
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterOrgEntityId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterOrgEntityId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Entity"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterCountryId"} editorType="dxSelectBox" name={"masterCountryId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterCountryId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterCountryId') && hcData.masterCountryId !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterCountryId']
                      },
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterCountryId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterCountryId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Base Country"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterOrgVerticalId"} editorType="dxSelectBox" name={"masterOrgVerticalId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterOrgVerticalId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterOrgVerticalId') && hcData.masterOrgVerticalId !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterOrgVerticalId']
                      },
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterOrgVerticalId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterOrgVerticalId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Vertical"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterOrgFunctionId"} editorType="dxSelectBox" name={"masterOrgFunctionId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterOrgFunctionId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterOrgFunctionId') && hcData.masterOrgFunctionId !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterOrgFunctionId']
                      },
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterOrgFunctionId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterOrgFunctionId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Function"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterLevelId"} editorType="dxSelectBox" name={"masterLevelId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterLevelId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterLevelId') && hcData.masterLevelId !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterLevelId']
                      },
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterLevelId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterLevelId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Level"} />
                  </SimpleItem>

                  <SimpleItem dataField={"job_name"} name={"job_name"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "job_name")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('job_name') && hcData.job_name !== null}
                    component={(props) => FormViewJD(props, hcData, hcJdView)}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['job_name']
                      },
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "job_name")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      setValue: (e, dataIndex) => {
                        hcData[dataIndex] = e
                        setHcData(hcData)
                      }
                    }}>
                    <Label text={"View JD"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterCatId"} editorType="dxSelectBox" name={"masterCatId"}
                    isRequired={false}
                    visible={toShowCats.includes(getUserInfo()?.id)}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterCatId']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterCatId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: true,
                    }}>
                    <Label text={"CATS"} />
                  </SimpleItem>

                  <SimpleItem dataField={"market"} editorType="dxTagBox" name={"market"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "market")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('market') && hcData.market !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['market']
                      },
                      showSelectionControls: true,
                      searchEnabled: true,
                      dataSource: hcDropdownData.market,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "market")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Market"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterOrgHfmId"} editorType="dxSelectBox" name={"masterOrgHfmId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterOrgHfmId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterOrgHfmId') && hcData.masterOrgHfmId !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterOrgHfmId']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterOrgHfmId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterOrgHfmId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"HFM"} />
                  </SimpleItem>

                  <SimpleItem dataField={"master_clients"} editorType="dxTagBox" name={"master_clients"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "master_clients")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('master_clients') && hcData.master_clients !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['master_clients']
                      },
                      showSelectionControls: true,
                      searchEnabled: true,
                      dataSource: hcDropdownData.master_clients,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "master_clients")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      maxDisplayedTags: MAX_DISPLAY_TAGS,
                      onMultiTagPreparing: (e) => onMultiTagPreparing(e),
                    }}>
                    <Label text={"Client"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterJobTypeId"} editorType="dxSelectBox" name={"masterJobTypeId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterJobTypeId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterJobTypeId') && hcData.masterJobTypeId !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterJobTypeId']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterJobTypeId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterJobTypeId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Job Type"} />
                  </SimpleItem>

                  <SimpleItem dataField={"hireDate"} editorType="dxDateBox" name={"hireDate"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "hireDate")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('hireDate') && hcData.hireDate !== null && hcData.hasOwnProperty('masterJobTypeId') && hcData.masterJobTypeId === 1}
                    component={(props) => FormDateBox(props, hcData, permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "hireDate")?.editable && !hcData.cancelled && hcData.needApproval ? true : false, true)}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['hireDate']
                      },
                      //openOnFieldClick: true,
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "hireDate")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      setValue: (e, dataIndex) => {
                        hcData[dataIndex] = e
                        setHcData(hcData)
                      }
                    }}>
                    <Label text={"HC Required on"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterManagementTypeId"} editorType="dxSelectBox" name={"masterManagementTypeId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterManagementTypeId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterManagementTypeId') && hcData.masterManagementTypeId !== null && hcData.hasOwnProperty('masterJobTypeId') && hcData.masterJobTypeId === 1}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterManagementTypeId']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterManagementTypeId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterManagementTypeId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Management Type"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterGigId"} editorType="dxSelectBox" name={"masterGigId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterGigId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterGigId') && hcData.hasOwnProperty('masterJobTypeId') && hcData.masterJobTypeId === 2}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterGigId']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterGigId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterGigId")?.editable && !hcData.cancelled || (hasDataChanged && !hcData.cancelled && permissions && permissions.canCreate) ? false : true,
                    }}>
                    <Label text={"Gig"} />
                  </SimpleItem>

                  <SimpleItem dataField={"startDate"} editorType="dxDateBox" name={"startDate"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "startDate")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('startDate') && hcData.hasOwnProperty('masterJobTypeId') && hcData.masterJobTypeId === 2}
                    component={(props) => FormDateBox(props, hcData, permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "startDate")?.editable && !hcData.cancelled || (hasDataChanged && !hcData.cancelled && permissions && permissions.canCreate) ? true : false, true)}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['startDate']
                      },
                      //openOnFieldClick: true,
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "startDate")?.editable && !hcData.cancelled || (hasDataChanged && !hcData.cancelled && permissions && permissions.canCreate) ? false : true,
                      setValue: (e, dataIndex) => {
                        const d = { ...hcData }
                        hcData[dataIndex] = e
                        setHcData(prevState => {
                          const updatedObject = { ...prevState, ...d };
                          updatedObject[dataIndex] = e;
                          return updatedObject;
                        });
                        setHasDataChanged(true)
                      }
                    }}>
                    <Label text={"Start Date"} />
                  </SimpleItem>

                  <SimpleItem dataField={"endDate"} editorType="dxDateBox" name={"endDate"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "endDate")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('endDate') && hcData.hasOwnProperty('masterJobTypeId') && hcData.masterJobTypeId === 2}
                    component={(props) => FormDateBox(props, hcData, permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "endDate")?.editable && !hcData.cancelled || (hasDataChanged && !hcData.cancelled && permissions && permissions.canCreate) ? true : false, true)}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['endDate']
                      },
                      //openOnFieldClick: true,
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "endDate")?.editable && !hcData.cancelled || (hasDataChanged && !hcData.cancelled && permissions && permissions.canCreate) ? false : true,
                      setValue: (e, dataIndex) => {
                        const d = { ...hcData }
                        hcData[dataIndex] = e
                        setHcData(prevState => {
                          const updatedObject = { ...prevState, ...d };
                          updatedObject[dataIndex] = e;
                          return updatedObject;
                        });
                        setHasDataChanged(true)
                      }
                    }}>
                    <Label text={"End Date"} />
                  </SimpleItem>

                  <SimpleItem dataField={"hiringManager"} editorType="dxSelectBox" name={"hiringManager"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "hiringManager")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('hiringManager') && hcData.hiringManager !== null}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['hiringManager']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.hiringManager,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "hiringManager")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Hiring Manager"} />
                  </SimpleItem>

                  <SimpleItem dataField={"billable"} editorType="dxCheckBox" name={"billable"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "billable")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "billable")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "billable")?.is_visible : true}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['billable']
                      },
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "billable")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Billable"} />
                  </SimpleItem>

                  <SimpleItem dataField={"critical"} editorType="dxCheckBox" name={"critical"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "critical")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "critical")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "critical")?.is_visible && hcData.hasOwnProperty('masterJobTypeId') && hcData.masterJobTypeId === 1 : hcData.hasOwnProperty('masterJobTypeId') && hcData.masterJobTypeId === 1}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['critical']
                      },
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "critical")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Critical"} />
                  </SimpleItem>

                  <SimpleItem dataField={"justification"} editorType="dxTextArea" name={"justification"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "justification")?.required : false}
                    visible={true}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['justification']
                      },
                      readOnly: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "justification")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      disabled: false,
                    }}>
                    <Label text={"Justification"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterBudgetStatusId"} editorType="dxSelectBox" name={"masterBudgetStatusId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterBudgetStatusId")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "masterBudgetStatusId")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "masterBudgetStatusId")?.is_visible : false}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterBudgetStatusId']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterBudgetStatusId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterBudgetStatusId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Budget Status"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterCurrencyId"} editorType="dxSelectBox" name={"masterCurrencyId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterBudgetStatusId")?.required && hcData.masterBudgetStatusId === 2 : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "masterCurrencyId")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "masterCurrencyId")?.is_visible : false}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterCurrencyId']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterCurrencyId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterCurrencyId")?.editable && !hcData.cancelled && hcData.needApproval && hcData.masterBudgetStatusId === 2 ? false : true,
                    }}>
                    <Label text={"Currency"} />
                  </SimpleItem>

                  <SimpleItem dataField={"budget"} editorType="dxNumberBox" name={"budget"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterBudgetStatusId")?.required && hcData.masterBudgetStatusId === 2 : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "budget")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "budget")?.is_visible : false}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['budget']
                      },
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "budget")?.editable && !hcData.cancelled && hcData.needApproval && hcData.masterBudgetStatusId === 2 ? false : true,
                      showSpinButtons: true,
                      min: 0
                    }}>
                    <Label text={"Budget"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterRecruitmentStatusId"} editorType="dxSelectBox" name={"masterRecruitmentStatusId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterRecruitmentStatusId")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "masterRecruitmentStatusId")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "masterRecruitmentStatusId")?.is_visible : true}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['masterRecruitmentStatusId']
                      },
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterRecruitmentStatusId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterRecruitmentStatusId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Recruitment Status"} />
                  </SimpleItem>

                  {
                    hcData && hcData.hasOwnProperty('masterRecruiterId') && hcData.masterRecruiterId && hcData.masterRecruiterId.length
                      ? <SimpleItem dataField={"masterRecruiterId"} editorType="dxTagBox" name={"masterRecruiterId"}
                        isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterRecruiterId")?.required : false}
                        visible={hcData && hcData.hasOwnProperty('masterRecruiterId') && hcData.masterRecruiterId && hcData.masterRecruiterId.length}
                        editorOptions={{
                          elementAttr: {
                            class: hcData.fieldToHighlights?.['masterRecruiterId']
                          },
                          showSelectionControls: true,
                          searchEnabled: true,
                          dataSource: hcDropdownData.masterRecruiterId,
                          valueExpr: "id",
                          displayExpr: "label",
                          disabled: true,
                        }}>
                        <Label text={"Recruiter"} />
                      </SimpleItem>
                      : null
                  }

                  <SimpleItem dataField={"internalHeadcountId"} editorType="dxTextBox" name={"internalHeadcountId"}
                    disabled={true}
                    visible={hcData?.hasOwnProperty('internalHeadcountId') && hcData?.internalHeadcountId !== null && typeof hcData?.internalHeadcountId === "string"}
                    editorOptions={{
                      disabled: true
                    }}>
                    <Label text={"Internal HC ID"} />
                  </SimpleItem>

                  <SimpleItem dataField={"attachment"} name={"attachment"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "attachment")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('attachment') && hcData.attachment && hcData.attachment.length !== 0 && permissions && permissions.canCreate}
                    component={CustomFormFileInput}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['attachment']
                      },
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "attachment")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Attachment"} />
                  </SimpleItem>

                  <SimpleItem dataField={"attachments"} name={"attachments"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "attachments")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('attachments') && hcData.attachments && hcData.attachments.length !== 0 && permissions && permissions.canCreate}
                    component={CustomFormFileInput}
                    editorOptions={{
                      elementAttr: {
                        class: hcData.fieldToHighlights?.['attachments']
                      },
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "attachments")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Attachment"} />
                  </SimpleItem>

                  <SimpleItem dataField={"approver_attachments"} name={"approver_attachments"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "approver_attachments")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('approver_attachments') && hcData.approver_attachments && hcData.approver_attachments.length !== 0 || hcData.needApproval}
                    component={(props) => FormFileUploader(props, hcData)}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && !hcData.cancelled && hcData.needApproval ? false : true,
                      setValue: (e, dataIndex) => {
                        hcData[dataIndex] = e
                        setHcData(hcData)
                      }
                    }}>
                    <Label text={"Approver Attachments"} />
                  </SimpleItem>


                  <SimpleItem dataField={"submittedOn"} editorType="dxDateBox" name={"submittedOn"}
                              isRequired={false}
                              visible={hcData?.hc_version > 1}
                              component={(props) => FormDateBox(props, hcData,  false, true)}
                              editorOptions={{disabled: true}}>
                    <Label text={"Version Submission Date"} />
                  </SimpleItem>

                </GroupItem>
              </Form>
              {haveFormError ? <MDBox className="form-error">{formErrorMsg}</MDBox> : null}
              <MDBox style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "stretch" }}>
                {
                  hcData?.canInitiatorEditAfterApproval || (!hcData.hasOwnProperty('newRow') && hcData.isDraft) && permissions && permissions.canCreate
                    ? <Link to={generateLink(hcData)}>
                      <DEButton visible={hcData?.canInitiatorEditAfterApproval || (!hcData.hasOwnProperty('newRow') && hcData.isDraft) && permissions && permissions.canCreate} stylingMode={"contained"} type={"default"} text={""} title={"edit"} icon={"edit"} style={{ marginRight: "10px" }} />
                    </Link>
                    : ""
                }
                <DEButton visible={hasDataChanged && hcData.status && permissions && permissions.canCreate} stylingMode={"contained"} type={"success"} text={"Submit"} onClick={handleSubmit} style={{ marginRight: "10px" }} />
                <DEButton visible={hasDataChanged && hcData?.actionedByApproverId === null && hcData.status && permissions && permissions.canCreate} stylingMode={"contained"} type={"danger"} text={"Cancel"} onClick={handleCancelConvert} style={{ marginRight: "10px" }} />
                <DEButton visible={!canApproveReject && hasDataChanged && hcData.actionedByApproverId === null && hcData.needApproval && permissions && permissions.canApprove} stylingMode={"contained"} type={"default"} text={"Add"} onClick={handleFormAdd} style={{ marginRight: "10px" }} />
                <DEButton visible={canApproveReject && hcData.actionedByApproverId === null && hcData.needApproval && permissions && permissions.canApprove} stylingMode={"contained"} type={"success"} text={"Approve"} onClick={handleApprove} style={{ marginRight: "10px" }} />
                {!hasDataChanged && !hcData.needApproval && hcData.status && hcData.showInternalMobilityModal && hcData.cancelled !== true && permissions && permissions.canCreate ?
                  <MDBox style={{ marginRight: "10px", display: "inline-block" }}>
                    <InternalMobilityPopup
                      apiCallBack={apiCallBack}
                      hcData={returnEditedDataFormat(hcData)}
                      masterRequestTypeId={masterRequestTypeId}
                      transactionHeadcountGroupId={hcData?.transaction_headcount_group?.id}
                      user={hcData?.transaction_headcount_group?.userId}
                      id={hcData?.id}
                      employee={hcData?.masterEmployeeId ?? null}
                      mrId={hcData?.masterRecruiterId}
                      mrsId={hcData?.masterRecruitmentStatusId}
                      type={hcData?.candidateType ?? null}
                      cName={hcData?.candidateName ?? null}
                      masterEmployees={hcDropdownData?.masterEmployeeId ?? []}
                      jDate={hcData?.joiningDate}
                      masterRecruiters={hcDropdownData?.masterRecruiterId ?? []}
                      masterRecruitmentStatus={hcDropdownData?.masterRecruitmentStatusId ?? []}
                      routeKey={routeKey}
                      disableInternalSubmitButton={hcData?.disableInternalSubmitButton}
                      internalReplacementRequired={hcData?.internalReplacementRequired}
                      masterFreelancerId={hcDropdownData?.freelancerId ?? []}
                      masterCurrencyId={hcDropdownData?.masterCurrencyId ?? []}
                      freelancerDetails={{
                        freelancerId: hcData?.freelancerId,
                        rateType: hcData?.rateType,
                        rate: hcData?.rate,
                        masterFRCurrencyId: hcData?.masterFRCurrencyId,
                        contract: hcData?.contract,
                        contractFileName: hcData?.contractFileName,
                        noc: hcData?.noc,
                        nocFileName: hcData?.nocFileName,
                      }}
                    />
                  </MDBox> : null}
                {hcData?.canViewBriefingTemplate && !hcData.isDraft && hcData.cancelled !== true /* && permissions && permissions.canCreate */ ?
                  <MDBox style={{ marginRight: "10px", display: "inline-block" }}>
                    <BriefingTemplateModal id={hcData?.id} hcData={hcData?.isLive ? hcData : {...hcData, status: true}} apiCallBack={apiCallBack} />
                  </MDBox> : null}
                {hcData?.isLive && actionDropdownDatasource && actionDropdownDatasource.length > 0 && (
                  <ActionDropdown
                    permissions={permissions}
                    actionDropdownDatasource={actionDropdownDatasource}
                    setPopupVisibility={setPopupVisibility}
                    isFormFormView={true}
                  />
                )}
              </MDBox>

              {isReplacement && hcData && hcData?.permanentToFreeLancer && Object.keys(hcData.permanentToFreeLancer).length ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">Convert To Freelancer Details</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {hcData.permanentToFreeLancer?.steps && hcData.permanentToFreeLancer.steps.length ?
                        <MDBox pt={1}>
                          <ProgressSteps steps={hcData.permanentToFreeLancer.steps} />
                        </MDBox>
                        : null
                      }
                      {hcData.permanentToFreeLancer?.transaction_histories ?
                        <MDBox pt={1}>
                          {hcData.permanentToFreeLancer.transaction_histories.length !== undefined && hcData.permanentToFreeLancer.transaction_histories.length ?
                            <HcHistoryTable
                              recordType={"permanentToFreeLancer"}
                              tableHcHistoryData={hcData.permanentToFreeLancer.transaction_histories}
                              hcDataAccessValues={hcDataAccessValues}
                              hcDropdownData={hcDropdownData} />
                            : null
                          }
                        </MDBox>
                        : null
                      }
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }

              {isReplacement && hcData && hcData?.hold_process && Object.keys(hcData.hold_process).length ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">{masterTransactionType === "transaction_leaver" ? "Leaver Hold & ReActivate Details" : "Hold & ReActivate Details"}</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {hcData.hold_process?.steps && hcData.hold_process.steps.length ?
                        <MDBox pt={1}>
                          <ProgressSteps steps={hcData.hold_process.steps} />
                        </MDBox>
                        : null
                      }
                      {hcData.hold_process?.transaction_histories ?
                        <MDBox pt={1}>
                          {hcData.hold_process.transaction_histories.length !== undefined && hcData.hold_process.transaction_histories.length ?
                            <HcHistoryTable
                              recordType={"hold_process"}
                              tableHcHistoryData={hcData.hold_process.transaction_histories}
                              hcDataAccessValues={hcDataAccessValues}
                              hcDropdownData={hcDropdownData} />
                            : null
                          }
                        </MDBox>
                        : null
                      }
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }

              {isReplacement && hcData && hcData?.cancel_process && Object.keys(hcData.cancel_process).length ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">Cancel Details</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {hcData.cancel_process?.steps && hcData.cancel_process.steps.length ?
                        <MDBox pt={1}>
                          <ProgressSteps steps={hcData.cancel_process.steps} />
                        </MDBox>
                        : null
                      }
                      <MDBox pt={1}>
                        <HcHistoryTable
                          recordType={"cancel_process"}
                          tableHcHistoryData={[{ cancel_reason: hcData?.cancel_reason, updatedAt: hcData?.updatedAt }]}
                          hcDataAccessValues={hcDataAccessValues}
                          hcDropdownData={hcDropdownData} />
                      </MDBox>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }
            </div>
          </MDBox >
          : null
      }
    </React.Fragment >
  );
})
HcFormDetails.displayName = "HcFormDetails"
export default HcFormDetails;
