import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import App from "App";
import { MaterialUIControllerProvider } from "utils/context";
import { Store } from "./utils/context/store/Store";
import Basic from "./views/sign-in";
import { ThemeProvider } from "@mui/material/styles";
import themeRTL from "assets/theme/theme-rtl";
import DetectUserActivityInActivity from "./components/DetectUserActivityInActivity";
import * as serviceWorker from './serviceWorker';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getTimeDifference } from "./utils/services/Helpers";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { setupGlobalErrorHandlers } from "./utils/services/ErrorHandler";
import BriefingTemplateForm from "components/BriefingTemplateForm";
import useNewReleaseNotification from "./utils/hooks/useNewReleaseNotification";

const Main = () => {
  const loggedInTime = localStorage.getItem('loggedInTime')
  const { pathname } = useLocation();

  if (process.env.REACT_APP_MAINTENANCE_MESSAGE !== "")
  {
    localStorage.clear()
    sessionStorage.clear()
  }

  if (!localStorage.getItem('forceLogout') && pathname === "/views/briefing-template") {
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem('forceLogout', true)
  }

  if (localStorage.getItem("isLoggedIn") === null) {
    const searchParams = new URLSearchParams(window.location.search);
    const tokenParam = searchParams.get('token');
    window.location.pathname === "/views/briefing-template" ? localStorage.setItem('tokenParam', tokenParam) : "na"
    window.location.pathname !== "/" ? localStorage.setItem('redirectPath', window.location.pathname) : "na"
    return <ThemeProvider theme={themeRTL}>
      <ToastContainer style={{ fontSize: '14px', width: "400px" }} />
      <Basic />
    </ThemeProvider>;
  }

  if (loggedInTime === null && localStorage.getItem('cleared') === null) {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }

  if (loggedInTime && getTimeDifference(loggedInTime) >= 24) {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }

  if (localStorage.getItem('_token') === null) {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }

  if (localStorage.getItem('_token') !== null && pathname === "/views/briefing-template") {
    return <>
      <ToastContainer style={{ fontSize: '14px', width: "400px" }} />
      <BriefingTemplateForm />;
    </>
  }

  // Fix small screen resolution zoom issue
  let screenWidth = screen.width;
  let isFirefox = false
  if (navigator.userAgent.search("Firefox") > -1)
    isFirefox = true

  if (screenWidth > 1366 && screenWidth <= 1440) {
    if (isFirefox) {
      document.body.style.transform = "scale(0.95)";
      document.body.style.transformOrigin = "left top";
      document.body.style.width = "105.2%";
      document.body.style.setProperty("height", "105.2%", "important");
    }
    else {
      document.body.style.zoom = "95%";
    }
  }
  else if (screenWidth > 1280 && screenWidth <= 1366) {
    if (isFirefox) {
      document.body.style.transform = "scale(0.85)";
      document.body.style.transformOrigin = "left top";
      document.body.style.width = "117.6%";
      document.body.style.setProperty("height", "117.6%", "important");
    }
    else {
      document.body.style.zoom = "85%";
    }
  }
  else if (screenWidth >= 1025 && screenWidth <= 1280) {
    if (isFirefox) {
      document.body.style.transform = "scale(0.8)";
      document.body.style.transformOrigin = "left top";
      document.body.style.width = "125%";
      document.body.style.setProperty("height", "125%", "important");
    }
    else {
      document.body.style.zoom = "80%";
    }
  }

  return (
    <DetectUserActivityInActivity>
      <App />
    </DetectUserActivityInActivity>
  );

};

const RenderApp = () => {
  useNewReleaseNotification(); // Use the custom hook
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Prevent anyone to embed the site
    if (window.top !== window.self) {
      throw new Error('This page cannot be embedded within an iframe.');
      // top.location = self.location;
    }
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [loading])

  return (
    <>
      {
        loading ? <>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}><CircularProgress color="inherit" /></Backdrop>
          <MaterialUIControllerProvider>
            <Store>
              <Main />
            </Store>
          </MaterialUIControllerProvider>
        </> : <MaterialUIControllerProvider>
          <Store>
            <Main />
          </Store>
        </MaterialUIControllerProvider>
      }
    </>
  )
}

/**
 * Initializing Global Error handler
 **/
setupGlobalErrorHandlers()

ReactDOM.render(
  // <CacheBuster isEnabled={process.env.NODE_ENV === 'production'}>
    <ErrorBoundary>
      <BrowserRouter>
        <RenderApp />
      </BrowserRouter>
    </ErrorBoundary>,
  // </CacheBuster>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
