import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import MDBox from '../../../../components/MDBox';
import {
  areRequiredFieldsFilled, checkIfValueChanged,
  DEButton,
  getLevels,
  getObjectDifferences,
  getUserInfo,
  isObjectEmpty,
  sanitizeInput,
  showDialogBox
} from 'utils/services/Helpers';
import { Form, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import {
  FormDateBox,
  FormFileUploader,
  FormSelectJD,
  FormTagBox,
  FormTextArea,
  FormTextBox,
  ProgressSteps
} from '../../../../components/CustomDataGridComponents';
import useHeadcountRequest from 'utils/hooks/useHeadcountRequest';
import Swal from 'sweetalert2';

const HcForm = React.memo(({ formHcData, columns, dropDownData, permissions, dataSource, setDataSource, setHasDataChanged, routeKey, apiCallBack, isReplacement = false, setDgData, isDraft = false, isCloned = false, expandedIndex, setExpandedIndex = null, isInternal = false, internalEmpData = null, leaverDetails }) => {
  const { getJDByCombination, hcJdView, setHcJdView } = useHeadcountRequest()
  const formRef = useRef(null)
  const [formHcNewData, setFormHcNewData] = useState({})
  const [prvsFormData, setPrvsFormData] = useState({})
  const [fieldToHighlights, setFieldsToHighlights] = useState(formHcData?.fieldToHighlights ?? {})
  const [formDataChanged, setFormDataChanged] = useState(false)
  const [formClassName, setFormClassName] = useState(null)
  const [formErrorMsg, setFormErrorMsg] = useState(null)
  const [levelValue, setLevelValue] = useState(null)
  const [jdDet, setJdDet] = useState(null)
  const isNewRow = formHcData && (formHcData?.newRow) ? true : false
  const originalData = formHcData?.originalRecord ? { ...formHcData?.originalRecord} : { ...formHcData }
  const originalFormData = useRef(originalData);
  const toShowCats = process.env.REACT_APP_CATSTOSHOW ? process.env.REACT_APP_CATSTOSHOW?.split(',')?.map(Number) : []

  if (toShowCats.includes(getUserInfo()?.id) && !columns.filter(c => c.dataIndex === "masterCatId")?.length) {
    const index = columns.findIndex(c => c.dataIndex === "view_jd")
    if (index !== -1) {
      columns.splice(index + 1, 0, {
        type: 'select',
        fixed: "",
        title: "Cats",
        width: "",
        editable: true,
        ellipsis: true,
        required: false,
        dataIndex: 'masterCatId',
        visibleOnDg: false,
        is_visible: true,
        is_form_visible: true,
        is_sortable: false,
        is_searchable: false
      })
    }
    else columns.push({
      type: 'select',
      fixed: "",
      title: "Cats",
      width: "",
      editable: true,
      ellipsis: true,
      required: false,
      dataIndex: 'masterCatId',
      visibleOnDg: false,
      is_visible: true,
      is_form_visible: true,
      is_sortable: false,
      is_searchable: false
    })
  }

  useEffect(() => {
    localStorage.setItem('resize', true)
    if (Object.keys(formHcData).length) {
      setFormHcNewData(formHcData)
      if (formHcData?.canInitiatorEditAfterApproval && isObjectEmpty(prvsFormData)) setPrvsFormData(_.cloneDeep(formHcData))

      if (isCloned) {
        const combination = {
          masterOrgDivisionId: formHcData?.masterOrgDivisionId,
          masterOrgEntityId: formHcData?.masterOrgEntityId,
          masterCountryId: formHcData?.masterCountryId,
          masterOrgVerticalId: formHcData?.masterOrgVerticalId,
          masterOrgFunctionId: formHcData?.masterOrgFunctionId,
          masterLevelId: formHcData?.masterLevelId
        }
        const fn = async () => await getJDByCombination(combination, false, null)
        fn()
      }

      let formClass = null
      if (formHcData.hasOwnProperty('cancelled') && formHcData.cancelled) {
        formClass = "cancelled"
      }
      else if (formHcData.hasOwnProperty('needApproval') && formHcData.needApproval) {
        formClass = "needApproval"
      }
      else if (formHcData.hasOwnProperty('status') && formHcData.status) {
        formClass = "approved"
      }
      setFormClassName(formClass)

      if (formHcData.masterLevelId) {
        setLevelValue(formHcData.masterLevelId)
      }

      // if (formHcData?.transaction_headcount_group && formHcData?.transaction_headcount_group?.masterRequestTypeId) {
      //   setMasterRequestTypeId(formHcData.transaction_headcount_group.masterRequestTypeId)
      // }

      // if (isReplacement && masterRequestTypeIdForReplacements !== null) {
      //   setMasterHeadcountTypeId(masterRequestTypeIdForReplacements)
      // }
      // else if (formHcData?.transaction_headcount_group && formHcData?.transaction_headcount_group?.masterHeadcountTypeId) {
      //   setMasterHeadcountTypeId(formHcData.transaction_headcount_group.masterHeadcountTypeId)
      // }
    }
    // cleanup on unmount
    return () => {
      localStorage.removeItem('resize')
      setFormHcNewData({})
      setHcJdView([])
      // setMasterRequestTypeId(null)
      // setMasterHeadcountTypeId(null)
      setFormDataChanged(false)
      //setWantsToConvert(false)
      setFormClassName(null)
      setFormErrorMsg(null)
    }
  }, [])
  useEffect(() => {
    if (Array.isArray(hcJdView) && !hcJdView?.length
      && (Array.isArray(formHcNewData.hcJdView) && !formHcNewData.hcJdView?.length || formHcNewData.hcJdView === undefined)
      && formHcNewData.hasOwnProperty('masterOrgDivisionId')
      && formHcNewData?.masterOrgDivisionId !== null && formHcNewData.hasOwnProperty('masterLevelId')
      && formHcNewData?.masterLevelId !== null && formHcNewData.hasOwnProperty('masterOrgVerticalId')
      && formHcNewData?.masterOrgVerticalId !== null && formHcNewData.hasOwnProperty('masterOrgFunctionId')
      && formHcNewData?.masterOrgFunctionId !== null) {
      const combination = {
        masterOrgDivisionId: formHcNewData?.masterOrgDivisionId,
        masterOrgEntityId: formHcNewData?.masterOrgEntityId,
        masterCountryId: formHcNewData?.masterCountryId,
        masterOrgVerticalId: formHcNewData?.masterOrgVerticalId,
        masterOrgFunctionId: formHcNewData?.masterOrgFunctionId,
        masterLevelId: formHcNewData?.masterLevelId
      }
      const fn = async () => await getJDByCombination(combination, false, null)
      fn()
    }
  }, [levelValue])
  useEffect(() => {
    if (Array.isArray(hcJdView) && hcJdView?.length) {
      formHcNewData['hcJdView'] = hcJdView
      setFormHcNewData(formHcNewData)
    }
  }, [hcJdView])
  useEffect(() => { }, [formHcNewData])
  useEffect(() => { }, [fieldToHighlights])

  // Function to update an object based on its ID
  function updateObjectById(array, id, updatedObject) {
    // Find the index of the object with the matching ID
    const index = array.findIndex(obj => obj.id === id)

    // If the object with the matching ID is found, update it
    if (index !== -1) array[index] = { ...array[index], ...updatedObject }
    else array.push({ id, ...updatedObject })

    // Return the updated array
    return array
  }

  /**
   * Handle form fields data change
   **/
  const formFieldDataChanged = (e) => {
    let hcDataCopy = formHcNewData

    if (!hcDataCopy?.newRow && !hcDataCopy?.isDraft) checkIfValueChanged(originalFormData, e.dataField, e.value, setFieldsToHighlights)

    if (e.dataField === "masterOrgDivisionId") {
      if (Array.isArray(hcJdView) && hcJdView?.length) setHcJdView([])
      hcDataCopy.hcJdView = []
      hcDataCopy.masterOrgEntityId = null
      hcDataCopy.masterCountryId = null
      hcDataCopy.masterOrgVerticalId = null
      hcDataCopy.masterOrgFunctionId = null
      hcDataCopy.masterLevelId = null
      hcDataCopy.transactionJdId = null
      hcDataCopy.view_jd = null
      //hcDataCopy.job_name = null
      setLevelValue(null)

    }
    else if (e.dataField === "masterOrgEntityId") {
      if (Array.isArray(hcJdView) && hcJdView?.length) setHcJdView([])
      hcDataCopy.hcJdView = []
      hcDataCopy.masterCountryId = null
      hcDataCopy.masterOrgVerticalId = null
      hcDataCopy.masterOrgFunctionId = null
      hcDataCopy.masterLevelId = null
      hcDataCopy.transactionJdId = null
      hcDataCopy.view_jd = null
      //hcDataCopy.job_name = null
      setLevelValue(null)

    }
    else if (e.dataField === "masterCountryId") {
      if (Array.isArray(hcJdView) && hcJdView?.length) setHcJdView([])
      hcDataCopy.hcJdView = []
      hcDataCopy.masterOrgVerticalId = null
      hcDataCopy.masterOrgFunctionId = null
      hcDataCopy.masterLevelId = null
      hcDataCopy.transactionJdId = null
      hcDataCopy.view_jd = null
      //hcDataCopy.job_name = null
      setLevelValue(null)

    }
    else if (e.dataField === "masterOrgVerticalId") {
      if (Array.isArray(hcJdView) && hcJdView?.length) setHcJdView([])
      hcDataCopy.hcJdView = []
      hcDataCopy.masterOrgFunctionId = null
      hcDataCopy.masterLevelId = null
      hcDataCopy.transactionJdId = null
      hcDataCopy.view_jd = null
      setLevelValue(null)
      //hcDataCopy.job_name = null
    }
    else if (e.dataField === "masterOrgFunctionId") {
      if (Array.isArray(hcJdView) && hcJdView?.length) setHcJdView([])
      hcDataCopy.hcJdView = []
      hcDataCopy.masterLevelId = null
      hcDataCopy.transactionJdId = null
      hcDataCopy.view_jd = null
      //hcDataCopy.job_name = null
      setLevelValue(null)

    }
    else if (e.dataField === "masterLevelId") {
      if (Array.isArray(hcJdView) && hcJdView?.length) setHcJdView([])
      hcDataCopy.hcJdView = []
      hcDataCopy.transactionJdId = null
      hcDataCopy.view_jd = null
      //hcDataCopy.job_name = null
      setLevelValue(e.value)
    }
    else if (e.dataField === "masterJobTypeId") {
      const st = hcDataCopy.cStatus
      const status = st?.toLowerCase() === "Approved".toLowerCase() || st?.toLowerCase() === "Closed".toLowerCase()

      if (!status) {
        if (hcDataCopy.masterJobTypeId === 2) {
          hcDataCopy.hireDate = null
          hcDataCopy.masterManagementTypeId = null
          hcDataCopy.critical = false
          showDialogBox(() => { }, null, 'Yes, continue', null, 'To build a case for a Freelance need, the submission by the business must make sure that the case presented on TEOS includes a checklist verified from the CEO of the operation and Traffic managers on freelancing need and gap in delivery. This is based on a prerequisite that identified the gaps and risks towards client delivery. After collecting and analyzing the data that case must include analysis covering talent/teams resource utilization, workload patterns, time allocation, and business requirements, and results show a clear indication of a gap and unfound bench support within CATS, PGD or other markets. Only then the case for a Freelancing need can be submitted. This is to make sure there are no idle resources and underused capacity.', false)
          // hcDataCopy.justification = null
          // hcDataCopy.billable = false
        } else if (hcDataCopy.masterJobTypeId === 1) {
          hcDataCopy.masterGigId = null
          hcDataCopy.startDate = null
          hcDataCopy.endDate = null
          // hcDataCopy.billable = false
          // hcDataCopy.justification = null
        }
      }
      else {
        if (!hcDataCopy?.editedAfterApproval) {
          hcDataCopy.pToF = false
          hcDataCopy.wantsToConvertToFreelancer = hcDataCopy.masterJobTypeId !== null
        }

      }
    }

    setFormHcNewData(prevState => {
      const updatedObject = { ...prevState, ...hcDataCopy }
      if (formHcData?.canInitiatorEditAfterApproval) {

        updatedObject.editedAfterApproval = true
      }
      updatedObject[e.dataField] = e.value
      return updatedObject
    })
    if (!isReplacement) {
      setHasDataChanged(false)
      localStorage.setItem('HC', JSON.stringify(hcDataCopy))
    }
    if (isReplacement) {
      const leaverData = JSON.parse(localStorage.getItem('LR')) ?? leaverDetails
      if (leaverData.headcounts?.length) {
        leaverData.headcounts = updateObjectById(leaverData.headcounts, hcDataCopy.id, hcDataCopy)
      }
      else leaverData.headcounts.push(hcDataCopy)
      leaverDetails = leaverData
      // localStorage.setItem('LR', JSON.stringify(leaverData))
    }
    setFormDataChanged(true)
  }

  /**
   * @param options
   * function use get Entities by division
   **/
  function getEntitiesByDivision(options) {
    let uniqueEntities = []
    if (options && options.hasOwnProperty('masterOrgDivisionId') && options.masterOrgDivisionId !== null) {
      const filteredEntities = dropDownData['masterOrgEntityId']?.filter(d => d.masterOrgDivisionId === options?.masterOrgDivisionId) ?? []
      uniqueEntities = [...new Map(filteredEntities?.map(item => [item['id'], item])).values()]
    }
    else {
      const filteredEntities = dropDownData && dropDownData.hasOwnProperty('masterOrgEntityId') ? dropDownData['masterOrgEntityId'] : []
      uniqueEntities = [...new Map(filteredEntities?.map(item => [item['id'], item])).values()]
    }

    return uniqueEntities.length > 0 ? uniqueEntities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []
  }

  /**
   * @param options
   * function use get countries by division or entity
   **/
  function getCountriesByDivisionOrEntity(options) {
    let uniqueCountries = []
    if (options && options.hasOwnProperty('masterOrgDivisionId') && options.masterOrgDivisionId !== null && options.hasOwnProperty('masterOrgEntityId') && options.masterOrgEntityId !== null) {
      const filteredVerticals = dropDownData['masterCountryId'].filter(obj => obj.masterOrgDivisionId === options?.masterOrgDivisionId && obj.masterOrgEntityId === options?.masterOrgEntityId) ?? []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()]
    }
    else if (options && options.hasOwnProperty('masterOrgDivisionId') && options.masterOrgDivisionId !== null && !options.hasOwnProperty('masterOrgEntityId') || options?.masterOrgEntityId === null) {
      const filteredVerticals = dropDownData['masterCountryId'].filter(obj => obj.masterOrgDivisionId === options?.masterOrgDivisionId) ?? []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()]
    }
    else {
      const filteredVerticals = dropDownData && dropDownData.hasOwnProperty('masterCountryId') ? dropDownData['masterCountryId'] : []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()]
    }

    return uniqueCountries.length > 0 ? uniqueCountries.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []
  }

  /**
   * @param options
   * function use get verticals based on division and entity
   **/
  function getVerticalsByEntityAndDivision(options) {
    let uniqueVerticals = []
    if (options && options.hasOwnProperty('masterOrgDivisionId') && options.masterOrgDivisionId !== null && options.hasOwnProperty('masterOrgEntityId') && options.masterOrgEntityId !== null && options.hasOwnProperty('masterCountryId') && options.masterCountryId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options?.masterCountryId && obj.masterOrgEntityId === options?.masterOrgEntityId && obj.masterOrgDivisionId === options?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()]
    }
    else if (options && options.hasOwnProperty('masterOrgDivisionId') && options.masterOrgDivisionId !== null && options.hasOwnProperty('masterOrgEntityId') && options.masterOrgEntityId === null && options.hasOwnProperty('masterCountryId') && options.masterCountryId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options?.masterCountryId && obj.masterOrgDivisionId === options?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()]
    }
    else if (options && options.hasOwnProperty('masterOrgDivisionId') && options.masterOrgDivisionId !== null && options.hasOwnProperty('masterOrgEntityId') && options.masterOrgEntityId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgEntityId === options?.masterOrgEntityId && obj.masterOrgDivisionId === options?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()]
    }
    else if (isObjectEmpty(options)) {
      const filteredVerticals = dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId') ? dropDownData['masterOrgVerticalId'] : []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()]
    }
    else {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgDivisionId === options?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()]
    }

    return uniqueVerticals.length > 0 ? uniqueVerticals.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []
  }

  /**
   * @param options
   * function use get functions based on verticals
   **/
  function getFunctionsByVerticals(options) {
    let allUniqueValues = []
    if (isObjectEmpty(options)) {
      const filteredCap = dropDownData && dropDownData.hasOwnProperty('masterOrgFunctionId') ? dropDownData['masterOrgFunctionId'] : []
      allUniqueValues = [...new Map(filteredCap?.map(item => [item['masterOrgFunctionId'], item])).values()]
    }
    else {
      const filteredCap = dropDownData['masterOrgFunctionId'].filter(cap => cap.masterOrgVerticalId === options?.masterOrgVerticalId && cap.masterOrgDivisionId === options?.masterOrgDivisionId && cap.masterOrgEntityId === options?.masterOrgEntityId && cap.masterCountryId === options?.masterCountryId) ?? []
      allUniqueValues = [...new Map(filteredCap?.map(item => [item['masterOrgFunctionId'], item])).values()]

    }

    return allUniqueValues.length > 0 ? allUniqueValues.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []
  }

  /**
   * @param options
   * get levels by division entity country and vertical
   **/
  function getLevelsLocal(options) {
    let uniqueEntities = []
    const allLevels = dropDownData && dropDownData.hasOwnProperty('masterLevelId') ? dropDownData['masterLevelId'] : []
    if (options && options.masterOrgDivisionId !== null) {
      const vet = dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId') && dropDownData['masterOrgVerticalId']?.length ? dropDownData['masterOrgVerticalId'] : []
      uniqueEntities = getLevels(allLevels, vet, options.masterOrgDivisionId, options.masterOrgEntityId, options.masterCountryId, options.masterOrgVerticalId, dropDownData.hasOwnProperty('levels') ? dropDownData['levels'] : [], dropDownData.hasOwnProperty('LIds') ? dropDownData['LIds'] : [])
    }
    else {
      uniqueEntities = [...new Map(allLevels?.map(item => [item['id'], item])).values()]
    }

    return uniqueEntities.length > 0 ? uniqueEntities.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []
  }

  /**
   * use to handle form submit
   **/
  const handleSubmit = () => {
    if (formRef.current.instance.validate().isValid) {
      const isStructureSame = formHcNewData && formHcNewData.masterOrgDivisionId === formHcNewData.leaverDivisionId && formHcNewData.masterOrgEntityId === formHcNewData.leaverEntityId && formHcNewData.masterCountryId === formHcNewData.leaverCountryId && formHcNewData.masterLevelId === formHcNewData.leaverLevelId
      const values = getObjectDifferences(formHcNewData, prvsFormData)
      delete values['editedAfterApproval']
      delete values['hcJdView']

      // Validate custom fields
      if (formHcNewData?.canInitiatorEditAfterApproval && !Object.keys(values)?.length) setFormErrorMsg('No Change detected!')
      else if (formHcNewData?.view_jd === null || formHcNewData?.transactionJdId === null) {
        setFormErrorMsg('Please Select JD!')
      }
      else if (formHcNewData?.market === null || formHcNewData?.market === undefined || formHcNewData?.market?.length === 0) {
        setFormErrorMsg('Please Select Market!')
      }
      else if (formHcNewData?.master_clients === undefined || formHcNewData?.master_clients === null || formHcNewData?.master_clients === '' || !formHcNewData?.master_clients?.length) {
        setFormErrorMsg('Please Add Client!')
      }
      else if (formHcNewData?.masterJobTypeId === 1 && (formHcNewData?.hireDate === null || formHcNewData?.hireDate === undefined)) {
        setFormErrorMsg('Please Select HC Required on Date!')
      }
      else if (formHcNewData?.masterJobTypeId === 2 && (formHcNewData?.startDate === null || formHcNewData?.startDate === undefined)) {
        setFormErrorMsg('Please Select Start Date!')
      }
      else if (formHcNewData?.masterJobTypeId === 2 && (formHcNewData?.endDate === null || formHcNewData?.endDate === undefined)) {
        setFormErrorMsg('Please Select End Date!')
      }
      else if (formHcNewData?.masterJobTypeId === 2 && formHcNewData?.startDate && formHcNewData?.endDate && formHcNewData?.endDate < formHcNewData?.startDate) {
        setFormErrorMsg('End Date cannot be less than Start Date')
      }
      else if (!isInternal && !isStructureSame && isNewRow && (formHcNewData.attachment === null || (formHcNewData.attachment && formHcNewData.attachment?.length === 0)) || (formHcNewData.attachments === null || (formHcNewData.attachments && formHcNewData.attachments?.length === 0 && !(formHcNewData?.editedAfterApproval && formHcNewData?.canInitiatorEditAfterApproval)))) {
        setFormErrorMsg('Please Attach Necessary Files!')
      }
      else if (formHcNewData?.hasOwnProperty('isReset') && formHcNewData?.isReset && (formHcNewData.attachment === null || (formHcNewData.attachment && formHcNewData.attachment?.length === 0))) {
        setFormErrorMsg('Please Attach Necessary Files!')
      }
      else if (isDraft && (formHcNewData.attachment === null || (formHcNewData.attachment && formHcNewData.attachment?.length === 0)) && formHcNewData.attachments.every(e => e.hasOwnProperty('isDeleted') && e.isDeleted)) {
        setFormErrorMsg('Please Attach Necessary Files!')
      }
      else if ((formHcNewData?.editedAfterApproval && formHcNewData?.canInitiatorEditAfterApproval) && (formHcNewData.attachment === null || (formHcNewData.attachment && formHcNewData.attachment?.length === 0)) && formHcNewData.attachments.every(e => e.hasOwnProperty('isDeleted') && e.isDeleted)) {
        setFormErrorMsg('Please Attach Necessary Files!')
      }
      else if (formHcNewData.justification && !sanitizeInput(formHcNewData.justification)) {
        setFormErrorMsg("Invalid characters detected. Please remove any special characters from Justification.")
      }
      else {
        if (jdDet) {
          formHcNewData.job_name = jdDet?.job_name
        }
        if (formHcNewData.hasOwnProperty('attachment')) {
          formHcNewData["hasFile"] = true
        }
        if (isReplacement) {
          if (areRequiredFieldsFilled(columns, formHcNewData) === true) {
            if (formHcNewData?.editedAfterApproval) {
              const updatedValues = getObjectDifferences(formHcNewData, prvsFormData)
              const oldValues = getObjectDifferences(prvsFormData, formHcNewData)
              formHcNewData['objectToExecuteWorkflow'] = getObjectDifferences(formHcNewData, prvsFormData)
              if (updatedValues?.masterJobTypeId === 2) delete formHcNewData['hireDate']
              // Function to modify object values based on keys
              const updatedValuesString = Object.keys(updatedValues).map(key => {
                if (dropDownData.hasOwnProperty(key))
                  if (Array.isArray(oldValues[key])) {
                    // Map over the array and get the corresponding labels from dropDownData
                    const labels = updatedValues[key]
                      .map(val => dropDownData[key]?.find(d => d.id === val)?.label)
                      .filter(label => label) // Filter out undefined labels
                      .join(', '); // Join the labels as a comma-separated string

                    return { [key]: labels }; // Return the labels as a comma-separated string
                  }
                  else return { [key]: dropDownData[key]?.find(d => d.id === updatedValues[key])?.label }
                else return { [key]: typeof updatedValues[key] === 'boolean' ? updatedValues[key] ? "Yes" : "No" : updatedValues[key] }

              }).reduce((acc, cur) => ({ ...acc, ...cur }), {});  // Merge results into a new object
              const oldValuesString = Object.keys(oldValues).map(key => {
                if (dropDownData.hasOwnProperty(key))
                  if (Array.isArray(oldValues[key])) {
                    // Map over the array and get the corresponding labels from dropDownData
                    const labels = oldValues[key]
                      .map(val => dropDownData[key]?.find(d => d.id === val)?.label)
                      .filter(label => label) // Filter out undefined labels
                      .join(', '); // Join the labels as a comma-separated string

                    return { [key]: labels }; // Return the labels as a comma-separated string
                  }
                  else return { [key]: dropDownData[key]?.find(d => d.id === oldValues[key])?.label }
                else return { [key]: typeof oldValues[key] === 'boolean' ? oldValues[key] ? "Yes" : "No" : oldValues[key] }
              }).reduce((acc, cur) => ({ ...acc, ...cur }), {});  // Merge results into a new object

              // Merging old and new values into a new object
              const mergedValues = Object.keys(oldValuesString).reduce((acc, key) => {
                const title = columns.find(c => c.dataIndex === key)?.title
                if (title && key?.toLocaleLowerCase() !== "attachment") acc[title] = {
                  oldValue: oldValuesString[key] ?? "",
                  newValue: updatedValuesString[key]
                };
                return acc;
              }, {});

              console.log('mergedValues', mergedValues);
              formHcNewData['fieldToHighlights'] = fieldToHighlights
              formHcNewData['updatedValues'] = updatedValuesString
              formHcNewData['oldValues'] = oldValuesString
              formHcNewData['oldAndNewValues'] = mergedValues
              // formHcNewData['originalRecord'] = formHcData
            }
            const updatedDataSource = dataSource.map(item => item.id === formHcNewData.leaverId ? { ...item, headcounts: item.headcounts.map(h => h.id === formHcNewData.id ? formHcNewData : h) } : item)
            console.log('updatedDataSource', updatedDataSource)
            setTimeout(() => {
              setDataSource(updatedDataSource)
              setDgData(updatedDataSource)
            }, 1000)
            if (setExpandedIndex !== null) setExpandedIndex(null)
            if (!updatedDataSource.some(e => !e.masterOrgDivisionId)) {
              setTimeout(() => {
                setHasDataChanged(true)
              }, 1000)
            }
            else {
              setHasDataChanged(false)
            }
            setFormDataChanged(false)
          }

        }
        else {

          if (formHcNewData?.editedAfterApproval) {
            const updatedValues = getObjectDifferences(formHcNewData, prvsFormData)
            const oldValues = getObjectDifferences(prvsFormData, formHcNewData)
            formHcNewData['objectToExecuteWorkflow'] = getObjectDifferences(formHcNewData, prvsFormData)
            if (updatedValues?.masterJobTypeId === 2) delete formHcNewData['hireDate']
            // Function to modify object values based on keys
            const updatedValuesString = Object.keys(updatedValues).map(key => {
              if (dropDownData.hasOwnProperty(key))
                if (Array.isArray(oldValues[key])) {
                  // Map over the array and get the corresponding labels from dropDownData
                  const labels = updatedValues[key]
                    .map(val => dropDownData[key]?.find(d => d.id === val)?.label)
                    .filter(label => label) // Filter out undefined labels
                    .join(', '); // Join the labels as a comma-separated string

                  return { [key]: labels }; // Return the labels as a comma-separated string
                }
                else return { [key]: dropDownData[key]?.find(d => d.id === updatedValues[key])?.label }
              else return { [key]: typeof updatedValues[key] === 'boolean' ? updatedValues[key] ? "Yes" : "No" : updatedValues[key] }

            }).reduce((acc, cur) => ({ ...acc, ...cur }), {});  // Merge results into a new object
            const oldValuesString = Object.keys(oldValues).map(key => {
              if (dropDownData.hasOwnProperty(key))
                if (Array.isArray(oldValues[key])) {
                  // Map over the array and get the corresponding labels from dropDownData
                  const labels = oldValues[key]
                    .map(val => dropDownData[key]?.find(d => d.id === val)?.label)
                    .filter(label => label) // Filter out undefined labels
                    .join(', '); // Join the labels as a comma-separated string

                  return { [key]: labels }; // Return the labels as a comma-separated string
                }
                else return { [key]: dropDownData[key]?.find(d => d.id === oldValues[key])?.label }
              else return { [key]: typeof oldValues[key] === 'boolean' ? oldValues[key] ? "Yes" : "No" : oldValues[key] }
            }).reduce((acc, cur) => ({ ...acc, ...cur }), {});  // Merge results into a new object

            // Merging old and new values into a new object
            const mergedValues = Object.keys(oldValuesString).reduce((acc, key) => {
              const title = columns.find(c => c.dataIndex === key)?.title
              if (title && key?.toLocaleLowerCase() !== "attachment") acc[title] = {
                oldValue: oldValuesString[key] ?? "",
                newValue: updatedValuesString[key]
              };
              return acc;
            }, {});

            console.log('mergedValues', mergedValues);
            formHcNewData['fieldToHighlights'] = fieldToHighlights
            formHcNewData['updatedValues'] = updatedValuesString
            formHcNewData['oldValues'] = oldValuesString
            formHcNewData['oldAndNewValues'] = mergedValues
          }
          const newData = dataSource && dataSource?.map(item => item.id === formHcNewData.id ? formHcNewData : item)
          console.log("formHcNewData", formHcNewData)
          setFormErrorMsg(null)
          setDataSource(newData)
          setDgData(newData)
          localStorage.removeItem('HC')
          if (setExpandedIndex !== null) setExpandedIndex(null)
          if (!newData.some(e => !e.masterOrgDivisionId)) {
            setHasDataChanged(true)
          }
          else {
            setHasDataChanged(false)
          }
          setFormDataChanged(false)

        }
      }
    }
  }

  /**
   * use to handle form cancel
   **/
  const handleReset = () => {
    formHcData.isReset = true
    formHcData.market = []
    formHcData.master_clients = null
    formHcData.hireDate = null
    formHcData.hiringManager = null
    formHcData.masterManagementTypeId = null
    formHcData.attachment = []
    formHcData.startDate = null
    formHcData.endDate = null
    formHcData.justification = null
    formHcData.critical = false
    formHcData.job_name = null
    if (formHcData.hasOwnProperty('attachments')) delete formHcData.attachments
    if (formHcData.hasOwnProperty('transaction_files')) delete formHcData.transaction_files
    setFormHcNewData(formHcData)
    formRef.current.instance.resetValues()
    if (!isReplacement) {
      setHasDataChanged(false)
      const newData = dataSource && dataSource?.map(item => item.id === formHcData.id ? formHcData : item)
      setDataSource(newData)
      setDgData(newData)
    }
    setFormDataChanged(false)
    localStorage.removeItem('HC')
    localStorage.removeItem('LR')
  }

  const handleCancel = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete this record',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      heightAuto: false,
      height: '200px'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updData = dataSource.filter((item) => item.id !== formHcData.id)
        setDataSource(updData)
        setHasDataChanged(false)
        setFormDataChanged(false)
        localStorage.removeItem('HC')
        localStorage.removeItem('LR')
      }
    })
  }

  /**
   * @param columns
   * use to handle rendering of form fields
   **/
  const renderFormFields = (columns) => {
    return columns.map((col, index) => {
      if (col.type === 'select') {
        if (col.dataIndex === "masterOrgDivisionId") {
          if (isReplacement) {
            return (
              <SimpleItem key={index} name={col.dataIndex}
                editorType="dxSelectBox"
                dataField={col.dataIndex}
                isRequired={col.required}
                visible={col.is_form_visible}
                editorOptions={{
                  elementAttr: {
                    class: fieldToHighlights?.[col.dataIndex]
                  },
                  disabled: col.disabled,
                  showClearButton: true,
                  searchEnabled: true,
                  dataSource: dropDownData?.[col.dataIndex]?.map(item => ({ ...item, disabled: item.id !== formHcNewData.leaverDivisionId })),
                  valueExpr: "id",
                  displayExpr: "label",
                }}>
                <Label text={col.title} for={col.dataIndex} />
              </SimpleItem>
            )
          }
          else {
            return (
              <SimpleItem key={index} name={col.dataIndex}
                editorType="dxSelectBox"
                dataField={col.dataIndex}
                isRequired={col.required}
                visible={col.is_form_visible}
                editorOptions={{
                  elementAttr: {
                    class: fieldToHighlights?.[col.dataIndex]
                  },
                  disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                  showClearButton: true,
                  searchEnabled: true,
                  dataSource: dropDownData?.[col.dataIndex],
                  valueExpr: "id",
                  displayExpr: "label",
                }}>
                <Label text={col.title} for={col.dataIndex} />
              </SimpleItem>
            )
          }
        }
        else if (col.dataIndex === "masterOrgEntityId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterOrgDivisionId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: getEntitiesByDivision(formHcNewData),
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterCountryId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterOrgEntityId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: getCountriesByDivisionOrEntity(formHcNewData),
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterOrgVerticalId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterCountryId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: getVerticalsByEntityAndDivision(formHcNewData),
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterOrgFunctionId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterOrgVerticalId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: getFunctionsByVerticals(formHcNewData),
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterLevelId") {
          if (isInternal && internalEmpData) {
            const levelCode = dropDownData && dropDownData.hasOwnProperty('masterLevelId') ? dropDownData['masterLevelId']?.filter(e => e.id === internalEmpData?.newHc?.levelId)[0]?.sCode : []
            const filteredLevels = getLevelsLocal(formHcNewData) ?? []
            const finalData = filteredLevels?.map(item => ({
              ...item,
              disabled: levelCode.localeCompare(item.sCode) === 1
            }))
            return (
              <SimpleItem key={index} name={col.dataIndex}
                editorType="dxSelectBox"
                dataField={col.dataIndex}
                isRequired={col.required}
                visible={col.is_form_visible}
                editorOptions={{
                  elementAttr: {
                    class: fieldToHighlights?.[col.dataIndex]
                  },
                  disabled: col.disabled || (formHcNewData && formHcNewData.masterOrgFunctionId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                  showClearButton: true,
                  searchEnabled: true,
                  dataSource: finalData,
                  valueExpr: "id",
                  displayExpr: "label",
                }}>
                <Label text={col.title} for={col.dataIndex} />
              </SimpleItem>
            )
          } else {
            return (
              <SimpleItem key={index} name={col.dataIndex}
                editorType="dxSelectBox"
                dataField={col.dataIndex}
                isRequired={col.required}
                visible={col.is_form_visible}
                editorOptions={{
                  elementAttr: {
                    class: fieldToHighlights?.[col.dataIndex]
                  },
                  disabled: col.disabled || (formHcNewData && formHcNewData.masterOrgFunctionId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                  showClearButton: true,
                  searchEnabled: true,
                  dataSource: getLevelsLocal(formHcNewData),
                  valueExpr: "id",
                  displayExpr: "label",
                }}>
                <Label text={col.title} for={col.dataIndex} />
              </SimpleItem>
            )
          }
        }
        else if (col.dataIndex === "masterCurrencyId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required || (formHcNewData && formHcNewData.masterBudgetStatusId === 2)}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterBudgetStatusId !== 2 && formHcNewData.masterLevelId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: dropDownData?.[col.dataIndex],
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterRecruitmentStatusId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible && !isNewRow}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: dropDownData?.[col.dataIndex],
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterRecruiterId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: dropDownData?.[col.dataIndex],
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterManagementTypeId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible && (formHcNewData && formHcNewData.masterJobTypeId === 1)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex], // Pass the dynamic class here
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: dropDownData?.[col.dataIndex],
                valueExpr: "id",
                displayExpr: "label",
                className: fieldToHighlights?.[col.dataIndex]
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterGigId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible && (formHcNewData && formHcNewData.masterJobTypeId === 2)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterJobTypeId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: dropDownData?.[col.dataIndex],
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "hiringManager") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterJobTypeId === null || formHcNewData.masterJobTypeId === undefined) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: {
                  paginate: true,
                  pageSize: 10,
                  store: process.env.REACT_APP_ENV === 'production' ? dropDownData?.[col.dataIndex] ?? [] : dropDownData?.[col.dataIndex]?.sort((a, b) => b.label.includes('outlook.com') - a.label.includes('outlook.com')).map(item => ({ ...item, disabled: !item.label.includes('outlook.com') }))
                },
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === "masterJobTypeId" || col.dataIndex === "masterOrgHfmId") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterLevelId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: dropDownData?.[col.dataIndex],
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showClearButton: true,
                searchEnabled: true,
                dataSource: {
                  paginate: true,
                  pageSize: 10,
                  store: dropDownData?.[col.dataIndex] ?? []
                },
                valueExpr: "id",
                displayExpr: "label",
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      }
      else if (col.type === 'button') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            //editorType="dxSelectBox"
            dataField={col.dataIndex}
            isRequired={true}
            visible={col.is_form_visible}
            component={(props) => FormSelectJD(props, formHcNewData, formHcNewData?.hcJdView, col.editable, setJdDet)}
            editorOptions={{
              elementAttr: {
                class: fieldToHighlights?.['transactionJdId']
              },
              disabled: col.disabled || (formHcNewData && formHcNewData.masterLevelId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
              setValue: (e) => {
                if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, 'transactionJdId', e, setFieldsToHighlights)
                const d = { ...formHcNewData }
                formHcData['transactionJdId'] = e
                formHcData['view_jd'] = e
                setFormHcNewData(prevState => {
                  const updatedObject = { ...prevState, ...d }
                  if (formHcData?.canInitiatorEditAfterApproval) {

                    updatedObject.editedAfterApproval = true
                  }
                  updatedObject['transactionJdId'] = e
                  updatedObject['view_jd'] = e
                  return updatedObject
                })
                // formHcNewData['transactionJdId'] = e
                // formHcNewData['view_jd'] = e
                // setFormHcNewData(formHcNewData)
                if (!isReplacement) setHasDataChanged(false)
                setFormDataChanged(true)

              }
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        )
      }
      else if (col.type === 'multi-select') {
        if (col.dataIndex === "market") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              component={(props) => FormTagBox(props, formHcNewData, dropDownData, col.editable)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterLevelId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                setValue: (e, dataIndex) => {
                  formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setTimeout(() => {
                    if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                  }, 500)

                  // setFormDataChanged(true)

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              component={(props) => FormTagBox(props, formHcNewData, dropDownData, col.editable)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                setValue: (e, dataIndex) => {
                  formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setTimeout(() => {
                    if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                  }, 500)

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      }
      else if (col.type === 'toggle') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            editorType="dxSwitch"
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible}
            editorOptions={{
              elementAttr: {
                class: fieldToHighlights?.[col.dataIndex]
              },
              disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        )
      }
      else if (col.type === 'checkbox') {
        if (col.dataIndex === "critical") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxCheckBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible && (formHcNewData && formHcNewData.masterJobTypeId === 1)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        if (col.dataIndex === "billable") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxCheckBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: (col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)))
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxCheckBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval))
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }

      }
      else if (col.type === 'date') {
        if (col.dataIndex === "hireDate") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible && (formHcNewData && formHcNewData.masterJobTypeId === 1)}
              component={(props) => FormDateBox(props, formHcNewData, col.editable)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                setValue: (e, dataIndex) => {
                  const d = { ...formHcNewData }
                  if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                  formHcData[dataIndex] = e
                  setFormHcNewData(prevState => {
                    const updatedObject = { ...prevState, ...d }
                    if (formHcData?.canInitiatorEditAfterApproval) {

                      updatedObject.editedAfterApproval = true
                    }
                    updatedObject[dataIndex] = e
                    return updatedObject
                  })
                  // formHcNewData[dataIndex] = e
                  // setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setFormDataChanged(true)

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        if (col.dataIndex === "startDate" || col.dataIndex === "endDate") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible && (formHcNewData && formHcNewData.masterJobTypeId === 2)}
              component={(props) => FormDateBox(props, formHcNewData, col.editable)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                setValue: (e, dataIndex) => {
                  const d = { ...formHcNewData }
                  if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                  formHcData[dataIndex] = e
                  setFormHcNewData(prevState => {
                    const updatedObject = { ...prevState, ...d }
                    if (formHcData?.canInitiatorEditAfterApproval) {

                      updatedObject.editedAfterApproval = true
                    }
                    updatedObject[dataIndex] = e
                    return updatedObject
                  })
                  // formHcNewData[dataIndex] = e
                  // setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setFormDataChanged(true)

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              component={(props) => FormDateBox(props, formHcNewData, col.editable)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                setValue: (e, dataIndex) => {
                  const d = { ...formHcNewData }
                  if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                  formHcData[dataIndex] = e
                  setFormHcNewData(prevState => {
                    const updatedObject = { ...prevState, ...d }
                    updatedObject[dataIndex] = e
                    return updatedObject
                  })
                  // formHcNewData[dataIndex] = e
                  // setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setFormDataChanged(true)

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      }
      else if (col.type === 'int') {
        if (col.dataIndex === "budget") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxNumberBox"
              dataField={col.dataIndex}
              isRequired={col.required || (formHcNewData && formHcNewData.masterBudgetStatusId === 2)}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterBudgetStatusId !== 2) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showSpinButtons: true,
                min: 0
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxNumberBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                showSpinButtons: true,
                min: 0
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      }
      else if (col.type === 'file') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            dataField={col.dataIndex}
            isRequired={isInternal ? false : col.required}
            visible={col.is_form_visible}
            component={(props) => FormFileUploader(props, formHcNewData)}
            editorOptions={{
              elementAttr: {
                class: fieldToHighlights?.[col.dataIndex]
              },
              disabled: formHcNewData?.canInitiatorEditAfterApproval ? false : col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback)),
              setValue: (e, dataIndex) => {
                formHcNewData[dataIndex] = e
                if (formHcData?.canInitiatorEditAfterApproval) formHcNewData.editedAfterApproval = true
                setFormHcNewData(formHcNewData)
                // setTimeout(() => {
                //   if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                // },500)
              }
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        )
      }
      else if (col.type === 'textarea') {
        if (col.dataIndex === "justification") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || (formHcNewData && formHcNewData.masterLevelId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                setValue: (e, dataIndex) => {
                  const d = { ...formHcNewData }
                  if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                  formHcData[dataIndex] = e
                  setFormHcNewData(prevState => {
                    const updatedObject = { ...prevState, ...d }
                    if (formHcData?.canInitiatorEditAfterApproval) {

                      updatedObject.editedAfterApproval = true
                    }
                    updatedObject[dataIndex] = e
                    return updatedObject
                  })
                  // formHcNewData[dataIndex] = e
                  // setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setFormDataChanged(true)
                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                setValue: (e, dataIndex) => {
                  const d = { ...formHcNewData }
                  if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                  formHcData[dataIndex] = e
                  setFormHcNewData(prevState => {
                    const updatedObject = { ...prevState, ...d }
                    if (formHcData?.canInitiatorEditAfterApproval) {

                      updatedObject.editedAfterApproval = true
                    }
                    updatedObject[dataIndex] = e
                    return updatedObject
                  })
                  // formHcNewData[dataIndex] = e
                  // setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setFormDataChanged(true)

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      }
      else {
        // text & string types
        if (col.dataIndex === "master_clients") {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxTextBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              component={(props) => FormTextBox(props, formHcNewData, col.editable)}
              editorOptions={{

                disabled: col.disabled || (formHcNewData && formHcNewData.masterLevelId === null) || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || (formHcNewData?.canInitiatorEditAfterApproval)),
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcData['clients'] = e
                  // formHcData['master_clients'] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d }
                  //   updatedObject['clients'] = e
                  //   updatedObject['master_clients'] = e
                  //   return updatedObject
                  // })
                  formHcNewData['clients'] = e
                  formHcNewData['master_clients'] = e

                  // formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setFormDataChanged(true)

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxTextBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              component={(props) => FormTextBox(props, formHcNewData, col.editable)}
              editorOptions={{
                elementAttr: {
                  class: fieldToHighlights?.[col.dataIndex]
                },
                disabled: col.disabled,
                setValue: (e, dataIndex) => {
                  const d = { ...formHcNewData }
                  if (!formHcNewData?.newRow && !formHcNewData?.isDraft) checkIfValueChanged(originalFormData, dataIndex, e, setFieldsToHighlights)
                  formHcData[dataIndex] = e
                  setFormHcNewData(prevState => {
                    const updatedObject = { ...prevState, ...d }
                    if (formHcData?.canInitiatorEditAfterApproval) {

                      updatedObject.editedAfterApproval = true
                    }
                    updatedObject[dataIndex] = e
                    return updatedObject
                  })
                  // formHcNewData[dataIndex] = e
                  // setFormHcNewData(formHcNewData)
                  if (!isReplacement) setHasDataChanged(false)
                  setFormDataChanged(true)

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
      }
    })
  }

  return (
    <React.Fragment>
      {columns?.length ?
        <MDBox id="HcFormContainer">
          {isReplacement && formHcNewData && formHcNewData?.steps && formHcNewData?.steps.length ?
            <div style={{ padding: "16px" }}>
              <MDBox pt={2} pb={1}>
                <ProgressSteps steps={formHcNewData.steps} />
              </MDBox>
            </div>
            : null
          }
          <div style={{ padding: "16px" }} className={formClassName}>
            <Form id="HcForm" className="HcFormContent" key="id" ref={formRef}
              onFieldDataChanged={formFieldDataChanged}
              formData={formHcNewData}
              style={{ marginBottom: "30px" }}>
              <GroupItem colCount={2}>
                {columns?.length > 0 && renderFormFields(columns)}
              </GroupItem>
            </Form>
            {formErrorMsg !== null ? <MDBox className="form-error">{formErrorMsg}</MDBox> : null}
            <MDBox style={{ textAlign: "right" }}>
              {
                formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback) || formHcNewData?.canInitiatorEditAfterApproval
                  ? <>
                    <DEButton visible={permissions && permissions.canCreate} stylingMode={"contained"} type={"success"} text={"Add"} onClick={handleSubmit} style={{ marginLeft: "10px" }} />
                    <DEButton visible={permissions && permissions.canCreate && !formHcNewData?.canInitiatorEditAfterApproval} stylingMode={"contained"} type={"danger"} text={"Reset"} onClick={handleReset} style={{ marginLeft: "10px" }} />
                    {
                      !isReplacement && !formHcNewData.isRollback && !formHcNewData?.canInitiatorEditAfterApproval
                        ? <DEButton visible={permissions && permissions.canCreate} stylingMode={"contained"} type={"default"} text={"Cancel"} onClick={handleCancel} style={{ marginLeft: "10px" }} />
                        : null
                    }
                  </>
                  : null
              }
            </MDBox>
          </div>
        </MDBox>
        : null
      }
    </React.Fragment>
  )
})
HcForm.displayName = "HcForm"
export default HcForm
